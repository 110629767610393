/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import { useMemo } from 'react';
import cx from 'classnames';
import { TableContext } from './TableContext';
import styles from './Table.module.scss';
import type { TablePadding, TableSize } from './interface';

type Props = {
  /**
   * The content of the table, normally `TableHead` and `TableBody`.
   */
  children: React.ReactNode;

  /**
   * @ignore
   */
  className?: string;

  /**
   * Allows TableCells to inherit padding of the Table.
   */
  padding?: TablePadding;

  /**
   * Allows TableCells to inherit size of the Table.
   */
  size?: TableSize;

  /**
   * Set the header sticky.
   */
  stickyHeader?: boolean;
};

export function Table(props: Props) {
  const { className, padding = 'default', size = 'medium', stickyHeader = false, ...other } = props;
  const table = useMemo(
    () => ({
      padding,
      size,
      stickyHeader,
    }),
    [padding, size, stickyHeader]
  );
  return (
    <TableContext.Provider value={table}>
      <table
        tabIndex={0}
        className={cx(
          styles.root,
          {
            [styles.stickyHeader]: stickyHeader,
          },
          className
        )}
        {...other}
      />
    </TableContext.Provider>
  );
}
