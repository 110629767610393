/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import styles from './Collapse.module.scss';

type Props = {
  /**
   * The content node to be collapsed.
   */
  children?: React.ReactNode;

  /**
   * If `true`, the component will transition in.
   */
  in?: boolean;
};
/**
 * The Collapse transition is used by the
 * [Vertical Stepper](/components/steppers/#vertical-stepper) StepContent component.
 * It uses [react-transition-group](https://github.com/reactjs/react-transition-group) internally.
 */

function Collapse({ children, in: inProp }: Props) {
  const wrapperRef = React.useRef(null);
  const collapsedHeight = inProp ? 'auto' : 0;

  return (
    <div
      className={styles.container}
      style={{
        height: collapsedHeight,
      }}
      data-testid={'collapse-container'}
    >
      <div className={styles.wrapper} ref={wrapperRef}>
        <div className={styles.wrapperInner}>{children}</div>
      </div>
    </div>
  );
}

export default Collapse;
