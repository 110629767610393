/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { Tablelvl2Context } from '../Table/Tablelvl2Context';
import styles from './TableRow.module.scss';
const defaultComponent = 'tr';

type Props = {
  /**
   * Should be valid <tr> children such as `TableCell`.
   */
  children?: React.ReactNode;

  /**
   * @ignore
   */
  className?: string;

  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: string;

  /**
   * If `true`, the table row will shade on hover.
   */
  hover?: boolean;

  /**
   * If `true`, the table row will have the selected shading.
   */
  selected?: boolean;
  id?: string;
  style?: { backgroundColor: string };
  'data-testid'?: string;
};
/**
 * Will automatically set dynamic row height
 * based on the material table element parent (head, body, etc).
 */

export const TableRow = React.forwardRef<HTMLElement, Props>(function TableRow(props, ref) {
  const {
    className,
    component: Component = defaultComponent,
    hover = false,
    selected = false,
    ...other
  } = props;
  const tablelvl2 = React.useContext(Tablelvl2Context);
  return (
    <Component
      // @ts-expect-error fix type
      ref={ref}
      className={cx(
        styles.root,
        {
          [styles.head]: tablelvl2 && tablelvl2.variant === 'head',
          [styles.footer]: tablelvl2 && tablelvl2.variant === 'footer',
          [styles.hover]: hover,
          [styles.selected]: selected,
        },
        className
      )}
      role={Component === defaultComponent ? null : 'row'}
      {...other}
    />
  );
});
