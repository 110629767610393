/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { Tablelvl2Context } from '../Table/Tablelvl2Context';
import styles from './TableBody.module.scss';

const tablelvl2: { variant: 'body' | 'footer' | 'head' } = {
  variant: 'body',
};
const defaultComponent = 'tbody';

export function TableBody({ className, component: Component = defaultComponent, ...other }: Props) {
  return (
    <Tablelvl2Context.Provider value={tablelvl2}>
      <Component
        // @ts-expect-error fix type
        className={cx(styles.root, className)}
        role={Component === defaultComponent ? null : 'rowgroup'}
        {...other}
      />
    </Tablelvl2Context.Provider>
  );
}
type Props = {
  /**
   * The content of the component, normally `TableRow`.
   */
  children?: React.ReactNode;

  /**
   * @ignore
   */
  className?: string;

  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: string;
};
