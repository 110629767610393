/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getImagePaths } from '@/utils/getImagePaths';
import { useMediaQuery } from './useMediaQuery';

const getImage = ({ desktopImage, phoneImage, tabletImage, isDesktop, isPhone, isTablet }: any) => {
  if (isPhone) {
    return phoneImage;
  }
  if (isTablet) {
    return tabletImage;
  }
  if (isDesktop) {
    return desktopImage;
  } else {
    return desktopImage;
  }
};

/*
  This hook defaults to a desktop image before the media queries are calculated.
  It is recommended to use `getImagePaths` with `<Picture />` for images that appear
  above the fold and let the browser select the most appropriate size.
*/
const useResponsiveImage = (imagePath: string): string => {
  const imagePaths = getImagePaths(imagePath);
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  return getImage({ ...imagePaths, isDesktop, isPhone, isTablet });
};

export { useResponsiveImage };
