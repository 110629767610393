/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import styles from './AccordionDetails.module.scss';
type AccordionDetailsClasses = {
  root?: string;
};
type Props = {
  /**
   * The content of the accordion details.
   */
  children?: React.ReactNode;

  /**
   * @ignore
   */
  classes?: AccordionDetailsClasses;
};

function AccordionDetails({ classes = {}, children }: Props) {
  return <div className={cx(styles.root, { [classes.root || '']: classes.root })}>{children}</div>;
}

export default AccordionDetails;
