/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { pascalCase } from '../../utils/pascalCase';
import { TableContext } from '../Table/TableContext';
import { Tablelvl2Context } from '../Table/Tablelvl2Context';
import styles from './TableCell.module.scss';

type Props = {
  /**
   * Set the text-align on the table cell content.
   *
   * Monetary or generally number fields **should be right aligned** as that allows
   * you to add them up quickly in your head without having to worry about decimals.
   */
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';

  /**
   * The table cell contents.
   */
  children?: React.ReactNode;

  /**
   * @ignore
   */
  className?: string;

  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: string;

  /**
   * Sets the padding applied to the cell.
   * By default, the Table parent component set the value (`default`).
   */
  padding?: 'checkbox' | 'default' | 'none';

  /**
   * Set scope attribute.
   */
  scope?: string;

  /**
   * Specify the size of the cell.
   * By default, the Table parent component set the value (`medium`).
   */
  size?: 'medium' | 'small';

  /**
   * Set aria-sort direction.
   */
  sortDirection?: 'asc' | 'desc' | false;

  /**
   * Specify the cell type.
   * By default, the TableHead, TableBody or TableFooter parent component set the value.
   */
  variant?: 'body' | 'footer' | 'head';
  'data-testid'?: string;
  onFocus?: (e: any) => void;
  colSpan?: number;
  rowSpan?: number;
};
/**
 * The component renders a `<th>` element when the parent context is a header
 * or otherwise a `<td>` element.
 */

export function TableCell(props: Props) {
  const {
    align = 'inherit',
    className,
    component,
    padding: paddingProp,
    scope: scopeProp,
    size: sizeProp,
    sortDirection,
    variant: variantProp,
    ...other
  } = props;
  const table = React.useContext(TableContext);
  const tablelvl2 = React.useContext(Tablelvl2Context);
  const isHeadCell = tablelvl2 && tablelvl2.variant === 'head';
  let Component;

  if (component) {
    Component = component;
  } else {
    Component = isHeadCell ? 'th' : 'td';
  }

  let scope = scopeProp;

  if (!scope && isHeadCell) {
    scope = 'col';
  }

  const padding = paddingProp || (table?.padding ? table.padding : 'default');
  const size = sizeProp || (table?.size ? table.size : 'medium');
  const variant = variantProp || tablelvl2?.variant;
  let ariaSort = null;

  if (sortDirection) {
    ariaSort = sortDirection === 'asc' ? 'ascending' : 'descending';
  }

  return (
    <Component
      // @ts-expect-error fix type
      className={cx(
        styles.root,
        variant ? styles[variant] : null,
        {
          [styles.stickyHeader]: variant === 'head' && table && table.stickyHeader,
          [styles[`align${pascalCase(align)}`]]: align !== 'inherit',
          [styles[`padding${pascalCase(padding)}`]]: padding !== 'default',
          [styles[`size${pascalCase(size)}`]]: size !== 'medium',
        },
        className
      )}
      aria-sort={ariaSort}
      {...other}
    />
  );
}
