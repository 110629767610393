/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { useState } from 'react';
import { Paper } from '../Paper';
import Collapse from '../Collapse/Collapse';
import styles from './Accordion.module.scss';
type Props = {
  /**
   * The content of the accordion.
   */
  children: React.ReactNode;

  /**
   * @ignore
   */
  className?: string;
  classes?: any;

  /**
   * If `true`, expands the accordion by default.
   */
  defaultExpanded?: boolean;

  /**
   * Callback fired when the expand/collapse state is changed.
   *
   * @param {object} event The event source of the callback.
   * @param {boolean} expanded The `expanded` state of the accordion.
   */
  onChange?: Function;
  overrideExpanded?: boolean;
  disabled?: boolean;
};

function Accordion({
  children: childrenProp,
  className,
  classes,
  defaultExpanded = false,
  overrideExpanded,
  onChange,
  disabled = false,
  ...other
}: Props) {
  const [expanded, setExpandedState] = useState(defaultExpanded);

  React.useEffect(() => {
    if (overrideExpanded !== undefined) {
      setExpandedState(overrideExpanded);
    }
  }, [overrideExpanded]);
  const handleChange = React.useCallback(
    (event: Event) => {
      setExpandedState(!expanded);

      if (onChange) {
        onChange(event, !expanded);
      }
    },
    [expanded, onChange, setExpandedState]
  );
  const [summary, ...children] = React.Children.toArray(childrenProp);
  return (
    <Paper
      className={cx(
        cx(classes?.root, styles.root),
        {
          [cx(classes?.expanded, styles.expanded)]: expanded,
          [cx(classes?.disabled, styles.disabled)]: disabled,
        },
        className
      )}
      square={true}
      {...other}
    >
      {/* @ts-expect-error fix type */}
      {React.cloneElement(summary, {
        expanded,
        toggle: handleChange,
        disabled,
      })}
      <Collapse in={expanded}>
        <div
          // @ts-expect-error fix type
          aria-labelledby={summary.props.id}
          // @ts-expect-error fix type
          id={summary.props['aria-controls']}
          role="region"
        >
          {children}
        </div>
      </Collapse>
    </Paper>
  );
}

export default Accordion;
