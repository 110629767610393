/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import Hidden from '../../Hidden';
import azCommonStyles from '../../../theme/globals.module.scss';
import Accordion from '../../Accordion/Accordion';
import AccordionSummary from '@/components/Accordion/AccordionSummary';
import AccordionDetails from '../../Accordion/AccordionDetails';
import ExpandMoreIcon from '../../../../public/images/expand-more.svg';
import iconStyles from '../../../theme/iconStyles.module.scss';
import styles from './styles.module.scss';
import { useStreamLineSignIn } from '@/hooks/useStreamLineSignIn';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type ExpansionPanelClasses = {
  summary?: string;
  detailsRoot?: string;
  icon?: string;
};

type Props = {
  summary: React.ReactNode;
  content: React.ReactNode;
  hideSmall?: boolean;
  theme?: 'white' | 'grey';
  onChange?: Function;
  defaultExpandedProp?: boolean;
  overrideExpanded?: boolean;
  classes?: ExpansionPanelClasses;
  expandIcon?: React.ReactNode;
  alwaysShowBorder?: boolean;
  title?: string;
};

export default function CustomizedExpansionPanel({
  summary,
  content,
  theme = 'white',
  onChange,
  hideSmall,
  defaultExpandedProp = false,
  alwaysShowBorder = false,
  overrideExpanded,
  expandIcon,
  title = '',
  classes = {},
}: Props) {
  const [expanded, setExpanded] = React.useState(defaultExpandedProp);
  const isSignInV2Enabled = useStreamLineSignIn();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (overrideExpanded !== undefined) {
      setExpanded(overrideExpanded);
    }
  }, [overrideExpanded]);
  const handleChange = (_: Event, expanded: boolean) => {
    setExpanded(expanded);
    onChange?.(expanded);
  };
  return (
    <div className={styles.expansionContainer} data-testid={'expansion-container'}>
      <Accordion
        className={`${styles[theme]} ${styles.accordionCurvedBorder}`}
        classes={{
          root: styles.root,
          expanded: styles.expanded,
        }}
        onChange={handleChange}
        defaultExpanded={defaultExpandedProp}
        overrideExpanded={overrideExpanded}
      >
        <AccordionSummary
          className={cx(
            azCommonStyles['az-body-1-heavy'],
            isSignInV2Enabled && isMobile
              ? styles.expansionSummarySignInV2
              : styles.expansionSummary,
            {
              [classes.summary || '']: classes.summary,
              [styles.expanded]: expanded && !alwaysShowBorder,
            }
          )}
          expandIcon={
            <Hidden className={styles.arrowExpand} smDown={hideSmall}>
              {expandIcon ? (
                expandIcon
              ) : (
                <ExpandMoreIcon
                  className={cx(iconStyles.defaultIconStyle, {
                    [classes.icon || '']: classes.icon,
                  })}
                />
              )}
            </Hidden>
          }
          role="presentation"
          title={title}
        >
          {summary}
        </AccordionSummary>
        {expanded && (
          <AccordionDetails
            classes={{
              root: cx(azCommonStyles['az-body-2-regular'], {
                [classes.detailsRoot || '']: classes.detailsRoot,
              }),
            }}
          >
            {content}
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
}
