/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { IconButton } from '../IconButton/IconButton';
import { ButtonBase } from '../ButtonBase/ButtonBase';
import styles from './AccordionSummary.module.scss';
import { useLabel } from '@/hooks/useLabels';
type Props = {
  /**
   * The content of the accordion summary.
   */
  children?: React.ReactNode;

  /**
   * @ignore
   */
  className?: string;

  /**
   * The icon to display as the expand indicator.
   */
  expandIcon?: React.ReactNode;

  /**
   * @ignore
   */
  onClick?: (event: MouseEvent) => unknown;
  expanded?: boolean;
  toggle?: (event: MouseEvent) => unknown;
  disabled?: boolean;
  role?: string;
  title: string;
};

function AccordionSummary({
  children,
  className,
  expandIcon,
  onClick,
  expanded,
  toggle,
  disabled,
  title,
  ...other
}: Props) {
  const lblExpansiblePanel = useLabel('label_ShelfPage_leftSideBar_ExpansiblePanel');

  const handleChange = (event: any) => {
    if (toggle) {
      toggle(event);
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <ButtonBase
      component="div"
      tabIndex={-1}
      className={cx(
        styles.root,
        {
          [styles.disabled]: disabled,
          [styles.expanded]: expanded,
        },
        className
      )}
      onClick={handleChange}
      {...other}
    >
      <div
        className={cx(styles.content, {
          [styles.expanded]: expanded,
        })}
      >
        {children}
      </div>
      {expandIcon && (
        <IconButton
          className={cx(styles.expandIcon, {
            [styles.expanded]: expanded,
          })}
          edge="end"
          component="div"
          tabIndex={0}
          data-testid="expand-and-collapse-button"
          onClick={handleChange}
          disabled={disabled}
          aria-expanded={expanded}
          aria-label={`${title} ${lblExpansiblePanel}`}
        >
          {expandIcon}
        </IconButton>
      )}
    </ButtonBase>
  );
}

export default AccordionSummary;
