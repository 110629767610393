/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const getImagePaths = (imagePath: string) => {
  const sections = imagePath?.split('.');
  const extension = sections && sections[sections.length - 1];
  return {
    phoneImage: imagePath?.replace(`.${extension}`, `-m.${extension}`),
    tabletImage: imagePath?.replace(`.${extension}`, `-t.${extension}`),
    desktopImage: imagePath?.replace(`.${extension}`, `-d.${extension}`),
  };
};
