/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import { createContext } from 'react';
import type { TablePadding, TableSize } from './interface';

type TableContextProps = {
  padding: TablePadding;
  size: TableSize;
  stickyHeader: boolean;
};

export const TableContext = createContext<TableContextProps | undefined>(undefined);
