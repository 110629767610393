/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { Grid } from '../../Grid';
import Image, { akamaiLoader } from '@/components/NextImage';
import { useResponsiveImage } from '../../../hooks/useResponsiveImage';
import { SmartLink as Link } from '../../../utils/smartLink';
import azCommonStyles from '../../../theme/globals.module.scss';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import { useEagerLoad } from '../../../hooks/useEagerLoad';
import styles from './styles.module.scss';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { decodeUrlParam, parseUrl, sanitizeUrlWithParamList } from '@/utils/urlHelpers';
import { acceptableShelfQueryParams } from '@/constants/queryParams';
import type { GridContentBlock } from '@/types/legacy';

const gridTypes = {
  GRID4: 'GRID4',
};

type Props = {
  contentBlock: Omit<GridContentBlock['contentBlock'], 'contentType'>;
  // TODO: Fix type for this ref
  gridRef?: React.Ref<any>;
};
type ContainProps = {
  contain: boolean;
  children: React.ReactNode;
};

const ContainOverflow = ({ contain, children }: ContainProps) => (
  <div>
    {contain && (
      <div
        style={{
          maxWidth: '1600px',
          margin: '0 auto',
        }}
      >
        {children}
      </div>
    )}
    {!contain && children}
  </div>
);

const trackHomeLinkInteractionBySection = (linkName: string, section: string, id: string) => {
  clickTrack({
    homeLinkName: linkName,
    linkBySection: section,
    clickedProductId: id,
    eventType: 'homeInteractionsBySection',
  });
};

const MobileGridContent = (props: Props) => {
  const { contentBlock } = props;
  const eager = useEagerLoad({
    isEagerLoadDesktop: contentBlock?.isEagerLoadDesktop,
    isEagerLoadMobile: contentBlock?.isEagerLoadMobile,
    isEagerLoadTablet: contentBlock?.isEagerLoadTablet,
  });
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const homeLinkInteraction = (linkName: string, section: string) => {
    const sectionName =
      section
        .replace(/<[^>]+>/gi, '')
        .replace(/&nbsp;/gi, '')
        .trim() || '';
    trackHomeLinkInteractionBySection(linkName, sectionName, '');
  };

  const renderItemsContentText = (item: any) => {
    if (item.text) {
      return (
        <div
          className={cx(styles.gridItemText, isDesktop && azCommonStyles['az-title-5-medium'])}
          dangerouslySetInnerHTML={(() => ({
            __html: item.text,
          }))()}
        />
      );
    }
  };

  const renderMapGridItems = (getResponsiveImage: (imagePath: string) => string) => {
    const { contentBlock } = props;
    const grid =
      (contentBlock.childContentBlockList && Object.values(contentBlock.childContentBlockList)) ||
      [];
    let gridMapItems = null;
    if (grid) {
      gridMapItems = grid.map((item: any, index: number) => {
        let link = item.CTALink;
        if (link) {
          const {
            url,
            query: { intcmp },
          } = parseUrl(link);
          const sanitizedRedirectPath = sanitizeUrlWithParamList(
            {
              intcmp,
            },
            acceptableShelfQueryParams,
            url
          );

          link = decodeUrlParam(sanitizedRedirectPath, 'intcmp');
        }
        const key = `${item.CTALink}-${index}`;
        return (
          <Grid component="li" item md={3} xs={6} key={key}>
            <div className={`${styles.gridItem}`} data-testid={`featured-category-${index}`}>
              <Link
                className={styles.link}
                to={link || '#'}
                onClick={() => {
                  homeLinkInteraction(item.text, contentBlock.overallHeadLine);
                }}
                id={`homeLinkId-${index}`}
                samePage={true}
              >
                <Image
                  priority={eager && index < 6}
                  src={getResponsiveImage(item.imageURL)}
                  alt=""
                  height={100}
                  width={100}
                  loader={akamaiLoader}
                />

                {renderItemsContentText(item)}
              </Link>
            </div>
          </Grid>
        );
      });
    }

    return gridMapItems;
  };

  const deviceClassName = {
    desktop: contentBlock?.desktop || false,
    tablet: contentBlock?.tablet || false,
    mobile: contentBlock?.mobile || false,
  };
  let overallHeadLine = null;

  if (contentBlock.overallHeadLine) {
    overallHeadLine = (
      <div
        className={cx(styles.pageTitle, azCommonStyles['az-padding-bottom-4xs'])}
        dangerouslySetInnerHTML={(() => ({
          __html: contentBlock.overallHeadLine,
        }))()}
      />
    );
  }

  const layout = contentBlock?.layoutCode ?? '';

  // eslint-disable-next-line testing-library/render-result-naming-convention
  const renderMGItems = renderMapGridItems(useResponsiveImage);

  if (layout !== gridTypes.GRID4) {
    return null;
  }

  const theme = (contentBlock?.theme ?? '').toLowerCase() === 'white' ? styles.whiteTheme : '';

  return (
    <DeviceWrapper {...deviceClassName}>
      <Grid
        container
        spacing={0}
        className={cx(styles.container, theme, azCommonStyles['az-padding-top-m'])}
        ref={props.gridRef}
      >
        <Grid item xs={12} lg={12}>
          <ContainOverflow contain>
            <Grid container spacing={0}>
              <Grid item md={12} xs={12} lg={12}>
                {overallHeadLine}
              </Grid>

              <Grid item xs={12}>
                <div className={`${styles.separator}`} />
              </Grid>

              {contentBlock.CTAName && contentBlock.CTALink ? (
                <Grid item xs={12} className={styles.shopAllContainer}>
                  <Link
                    to={contentBlock.CTALink || '#'}
                    className={azCommonStyles['az-body-2-link']}
                    id="shopAllLink"
                    samePage={true}
                  >
                    <span>{contentBlock.CTAName}</span>
                  </Link>
                </Grid>
              ) : null}

              <Grid component="ul" container spacing={0} className={styles.gridContainer}>
                {renderMGItems}
              </Grid>
            </Grid>
          </ContainOverflow>
        </Grid>
      </Grid>
    </DeviceWrapper>
  );
};

export default MobileGridContent;
