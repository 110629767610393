/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const decodeUrlParam = (url: string, param: string) => {
  if (!url.includes(param)) {
    return url;
  }
  const [urlBeforeParam, urlWithParam] = url.split(param);
  let urlAfterParam = '';
  let extractedParam = urlWithParam;
  if (urlWithParam.includes('&')) {
    extractedParam = urlWithParam.substring(0, urlWithParam.indexOf('&'));
    urlAfterParam = urlWithParam.substring(urlWithParam.indexOf('&'));
  }
  const decodedParam = decodeURIComponent(extractedParam);

  return `${urlBeforeParam}${param}${decodedParam}${urlAfterParam}`;
};
