/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';

type Tablelvl2ContextProps = {
  variant: 'head' | 'body' | 'footer';
};

export const Tablelvl2Context = React.createContext<Tablelvl2ContextProps | undefined>(undefined);
